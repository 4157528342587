<template>
    <ul class="nav nav-tabs flex-row ms-2" ref="draggable" >
        <li class="nav-item position-relative"  :class="{'active':tab.current,'pinned':tab.pinned}" v-for="tab in tabsControl.tabs">
           
            <a class="nav-link py-1 rounded-0" aria-current="page" 
                :href="tab.href"
                :class="{'active bg-light-subtle':tab.current}"
                @click="tabClick(tab)" >
                <div v-if="tab.item && Object.keys(tab.item).length === 0" class="spinner-border spinner-border-sm" role="status">
                </div>
                    
                <small>
                    <slot :item="tab.item"> {{tab.title}}</slot>
                </small>
            </a>
             <button type="button" 
                    class="btn btn-link btn-sm p-0 position-absolute start-0 tab-btn pin-btn" 
                    :title="$t((tab.pinned?'Unpin tab':'Pin tab'))" 
                    @click = "pinTab(tab)">
                <i v-if="!tab.pinned" class="bi bi-pin-angle"></i>
                <i v-if="tab.pinned" class="bi bi-pin-fill"></i>
            </button>
            <button type="button" 
                    class="btn btn-link btn-sm p-0 position-absolute end-0 tab-btn close-button" 
                    aria-label="Close" 
                    :title="$t('Close tab')" 
                    @click = "closeTab(tab)">
                <i class="bi bi-x"></i>  
            </button>
        </li>
    </ul>
</template>

<script setup>
    import {ref,onMounted} from 'vue';
    import Sortable from 'sortable';
    //import TabsControl from 'o365.vue.modules.DesignerTabs.ts';

    const props = defineProps({
        dataObject:{
            required:false
        },
        tabsControl:{
            required:true
        }
    });
    const draggable = ref(null);

    const closeTab = (tab)=>{
       props.tabsControl.closeTab(tab);
    }

    const tabClick = (tab)=>{
        props.tabsControl.setActive(tab);
    }
    const pinTab = (tab)=>{
        tab.pinned = !tab.pinned;
    }

    onMounted(()=>{
        
        new Sortable(draggable.value,{
            animation: 150,
            ghostClass: "bg-ghost",
            chosenClass: "bg-chosen",
            dragClass: "bg-drag",
            handle: '.nav-link',
         
        })
    })

</script>
<style scoped>
    .nav-link{
        padding-right: 24px;
        padding-left: 24px;
       
    }

    .bg-ghost .nav-link{
        background:var(--bs-border-color);
    }
    .nav-item .close-button{
        display:none;
    }
    .nav-item.active .tab-btn{
        display:block;
    }

    .nav-item .pin-btn{
        display:none;
    }

    .nav-item.pinned .pin-btn{
        display:block;
    }
    .nav-item.active.pinned .pin-btn{
        display:block;
    }
    .nav-item:hover .tab-btn{
        display:block;
    }
    /*.tab-btn.close-button{
        display:none;
        
    }*/
    .tab-btn:hover{
        -webkit-text-stroke: 1px;
    }

    .close-button{
        margin-right: 0.2rem; 
        top: 0.2rem;
        font-size: 13pt;
    }
    .pin-btn{
        margin-left: 0.4rem; 
        top: 0.4rem;
        font-size: 10pt;
    }
    .spinner-border {
        width:13px;
        height:13px;
    }

</style>